import { createTheme } from "@mui/material";



const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#EAAA00", // Mustard yellow
            light: "#FFD166",
            dark: "#E58E1A",
            contrastText: "#2D3047",
        },
        secondary: {
            main: "#FFB81C", // Golden yellow
            light: "#FFD166",
            dark: "#F7A41D",
            contrastText: "#2D3047",
        },
        background: {
            default: "#f2f2f2",
            paper: "#FFFFFF",
            alert: "#E54B4B",
        },

        text: {
            primary: "#000000",
            secondary: "#ffffff",
            contrastText: "#8c8c8c",
            disabled: "#9DA3BB",
            white: "#FFFFFF",
        },
        card: {
            bg: "#FFFFFF",
            text: "#2D3047",
        },
        info: {
            main: "#3D9DF3",
            light: "#64B5F6",
            dark: "#1976D2",
        },
        success: {
            main: "#347928",
            light: "#4DD8AF",
            dark: "#05865F",
        },
        dialog: {
            primary: "#2D3047",
            secondary: "#FFB81C",
            paper: "#FFFFFF",
        },
        button: {
            blue: "#F7A41D", // Changed to mustard yellow
            gold: "#FFB81C",
            red: "#bf3131",
            green: "#06A77D",
            grey: "#9DA3BB",
        },
        miscBg: {
            bg: "   rgba(218, 218, 218, 1)",
            bg2: "rgba(45, 48, 71, 0.16)",
          }


    },
    typography: {
        fontFamily: "Roboto",
    },
});


export default theme;

