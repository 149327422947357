import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { AccessTime, CampaignOutlined, FileCopy } from '@mui/icons-material';


const Announcements = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [fileDialog, setFileDialog] = useState(false);
    const [fileSrc, setFileSrc] = useState("");
    const theme = useTheme();
    const handleFileClose = () => {
        setFileDialog(false);
        setFileSrc("");
    }

    const showFile = (fileLink) => {
        setFileSrc(fileLink);
        setFileDialog(true);
    }





    const employeeInfo = (sessionStorage.getItem("employeeInfo") !== null) ? JSON.parse(sessionStorage.getItem("employeeInfo")) : null;
    const employeeId = employeeInfo.data.employee.id;

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${employeeInfo.data.accessToken}`,
        },
    };

    const fetchAnnouncements = () => {
        setLoading(true);

        axios.get(`${process.env.REACT_APP_API_BACKEND}/api/kiosk/announcements/${employeeId}`, config)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
                setData(null);
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchAnnouncements();
    }
        , []);
    const toLocalTime = (date) => {
        return new Date(date).toLocaleDateString();
    }

    const handleMarkAsRead = (id) => {
        //set isRead to true in session storage
        const announcements = employeeInfo.data.announcements;
        const newAnnouncements = announcements.map((item) => {
            if (item.id === id) {
                item.isRead = true;
            }
            return item;
        }
        )
        employeeInfo.data.announcements = newAnnouncements;
        sessionStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
        axios.post(`${process.env.REACT_APP_API_BACKEND}/api/announcement/markasread/${id}/${employeeInfo.data.employee.id}`, {}, config)
            .then((res) => {
                console.log("Marked as read");
            }
            )
            .catch((err) => {
                console.log(err);
            }
            )

        //mark as read in data
        const newData = data.announcements.map((item) => {
            if (item.id === id) {
                item.isRead = true;
            }
            return item;
        }
        )
        setData({ ...data, announcements: newData });

    }



    return (
        <div style={{overflow: "auto", height: "70vh"}}>
            {loading ?
                (<Skeleton variant="rect" height={118} />)
                :
                (data && data.announcements &&
                    data.announcements.length !== 0 ?
                    data.announcements.map((item) => (
                        <Box
                            key={item.id}
                            sx={{
                                display: "flex",
                                padding: "10px 20px",
                                borderRadius: "10px",
                                margin: "10px 0",
                                cursor: "pointer",
                                backgroundColor: theme.palette.card.bg,
                                alignItems: "center"
                            }}
                        >
                            <Box>
                                <Typography sx={{ fontSize: "1.2rem" }}>
                                    {item.message}</Typography>
                                <Typography sx={{ display: "flex", alignItems: "center", fontWeight: '1.2rem' }}>
                                    <AccessTime sx={{ marginRight: "5px", fontSize: "1rem" }} />
                                    {toLocalTime(item.createdAt)}
                                </Typography>
                                {item.createdBy && <Typography sx={{ display: "flex", alignItems: "center" }}>
                                    Created By: {item.createdBy}
                                </Typography>}
                                {item.fileLink &&
                                    <Box  onClick={() => { showFile(item.fileLink) }} sx={{ display: "flex", alignItems: "center", width: "max-content", marginRight: "20px", backgroundColor: theme.palette.text.secondary, color: theme.palette.text.primary, borderRadius: "10px", padding: "5px" }}>
                                     View Attachment   <FileCopy sx={{ marginLeft: "5px", cursor: "pointer", fontSize: "20px" }} />
                                    </Box>
                                }
                            </Box>
                            {!item.isRead ?
                                <Box sx={{   width: "max-content", marginLeft: "auto" }}>
                                    <Button
                                        variant='outlined' sx={{ width: "max-content" }}
                                        onClick={() => handleMarkAsRead(item.id)}
                                    >Mark as Read</Button>
                                </Box>
                                :
                                null
                            }

                        </Box>
                    ))
                    :
                    <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
                        No Announcements
                        <CampaignOutlined sx={{ marginLeft: "10px" }} />
                    </Typography>

                )
            }
            <Dialog open={fileDialog} onClose={handleFileClose} fullWidth>
                <DialogTitle>File</DialogTitle>
                <DialogContent>
                    {
                        fileSrc.split('.').pop() === "pdf" ?
                            <iframe src={`${fileSrc}#toolbar=0`} style={{ width: '100%', height: '500px' }} />
                            :
                            <img src={fileSrc} style={{ width: '100%', height: '500px' }} />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFileClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Announcements;



