import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import EmployeesBox from '../components/EmployeesBox';
import Welcome from '../components/Welcome';
import PinAuth from '../components/PinAuth';
import './Main.css';

function Main() {
  const [employee, setEmployee] = useState(null);
  const [error, setError] = useState(null);
  const [showLogout, setShowLogout] = useState(false);


  const navigate = useHistory();
  const handleEmployee = (employee) => {
    setEmployee(employee);
  }


  const employeeInfo = (sessionStorage.getItem("employeeInfo") !== null) ? JSON.parse(sessionStorage.getItem("employeeInfo")) : null;
  useEffect(() => {
    if (employeeInfo !== null) {
      navigate.push(`/dashboard/${employeeInfo.data.employee.id}`);
    }
  }, [employeeInfo, navigate]);
  
  return (
    <div>
      <div className="main-screen-bg">
      </div>
      <div className="main-screen">
        <Grid container style={{ padding: "10px", marginTop: "5rem" }}>
          <Grid item xs={6} md={6}>
            <EmployeesBox className="employee-box" handleEmployee={handleEmployee} />
          </Grid>
          <Grid item xs={6} md={6}>
            {employee ? (
              <PinAuth employee={employee} />
            ) : (
              <Welcome />
            )}
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>

  )
}

export default Main;