import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import logo from "../crinitis-logo.png";

const Footer = () => {
  return (
    // <Box
    //   sx={{
    //     position: "fixed",
    //     bottom: "0",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     display: "flex",
    //     justifyContent: "center",
    //     paddingBottom: "2rem"
    //   }}
    // >
    //   <span>
    //     <Typography
    //       variant="caption"
    //       component="div"
    //       style={{ textAlign: "center", display: "inline" }}
    //     >
    //       Powerd By
    //     </Typography>
    //   </span>
    //   <img
    //     src="https://cms.com.au/assets/images/cms-logo.png"
    //     style={{ maxWidth: "100px", maxHeight: "30px", paddingLeft: "5px" }}
    //     alt=""
    //   />
    // </Box>
    <div
    style={{
    
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "1rem",
      marginBottom: "1rem",
      position: "fixed",
      right: "10px",
      bottom: "0"

    }}
    >
      
      <img
        src="https://crinitis-ms-app-files.s3.ap-southeast-2.amazonaws.com/assets/empstar-black.png"
        style={{ maxWidth: "100px", maxHeight: "100px", paddingLeft: "5px" }}
        alt=""
      />

    </div>
  );
};

export default Footer;
