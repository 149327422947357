import * as React from "react";
import { AppBar, Button, Dialog, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { Toolbar } from "@mui/material";
import { IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from "axios";

export default function Navbar() {
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const theme = useTheme();
  //refresh react page
  const refreshPage = () => {
    window.location.reload(false);
  };
  
  const employeeInfo = JSON.parse(sessionStorage.getItem("employeeInfo"));
  const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
  const logo = companyInfo.logo;
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${companyInfo.JWT_TOKEN}`,
    },
  };
  const mainSignOut = () => {
    // axios.post(`${process.env.REACT_APP_API_BACKEND}/api/kiosk/logout`, {}, config);
    // sessionStorage.removeItem("employeeInfo");
    // localStorage.removeItem("companyInfo");
    axios.post(`${process.env.REACT_APP_API_BACKEND}/api/kiosk/logout`, {}, config)
    .then((res) => {
      sessionStorage.removeItem("employeeInfo");
      localStorage.removeItem("companyInfo");
      window.location.reload(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return (
    <>
    <Box
      sx={{
        flexGrow: 1,
        position: "fixed",
        top: "0",
        width: "100%",
        height:"6rem",
        margin: "0",
        zIndex: "1000"
      }}
    >
      <AppBar position="static" style={{ backgroundColor:theme.palette.background.paper }}>
        <Toolbar
          style={{
            justifyContent: "space-between",
          }}
        >
          {!employeeInfo ? (
            <ExitToAppIcon 
            onClick={() => setConfirmDialog(true)}
            fontSize="large"
            sx={{ color: theme.palette.text.primary, fontSize: "2rem" }}
            />
          ) : (
            <div style={{ visibility: "hidden" }}>lorem</div>)}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <img style={{ height: "5rem", marginRight: "10px" }} src={logo} alt="Crinits" />
            {/* <div style={{ fontSize: "1.3rem" }}>{companyInfo.companyName} - {companyInfo.branchName}</div> */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 , fontSize: "1.3rem"}}>
              {companyInfo.companyName} - {companyInfo.branchName}
            </Typography>
          </div>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={refreshPage}
          >
            <RefreshIcon className="svg_icons" style={{ width: "2rem" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
    <div style={{ position: "fixed", bottom: "0", left: "0",  textAlign: "center", padding: "0.5rem" }}>
      ID#{companyInfo.sessionId}
    </div>
    <Dialog
      open={confirmDialog}
      onClose={() => setConfirmDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "white",
          color: "black",
          padding: "10px",
          borderRadius: "10px",
        },
      }}

    >
      <Typography variant="h5" component="div" style={{ padding: "20px" }}>
        Are you sure you want to logout?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "20px",
        }}
      >
        <Button variant="contained" color="error" onClick={mainSignOut} style={{ marginRight: "15px" }}>
          Yes
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setConfirmDialog(false)}
          sx={{ marginLeft: "15px" }}
        >
          No
        </Button>

      </Box>
    </Dialog>

    </>
  );
}
