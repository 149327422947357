import * as React from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import ShiftTimeLine from "./ShiftTimeline";
import { Avatar, Chip, TextField, Typography, useTheme } from "@mui/material";
import './Shifts.css';

export default function ShiftBox(props) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    };
    const {
        startImage,
        endImage,
        startDate,
        endDate,
        totalBreak,
        shiftWithoutBreak,
        totalShiftLength,
        approved
    } = props.shift;


    const subtractTime = (time1, time2) => {
        //smaple 2023-08-11T08:54:19.659Z, 2023-08-11T09:31:42.968Z
        const date1 = new Date(time1);
        const date2 = new Date(time2);
        const diff = date2.getTime() - date1.getTime();
        //round to hh mm
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
        //return hh:mm
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    };

    return (
        <>
        <Box
    sx={{
        width: "120px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        cursor: "pointer",  // Add a pointer cursor when hovering
        backgroundColor: theme.palette.card.bg,
        transition: "background-color 0.3s ease", // Add a smooth transition for background color change
        margin: "auto",
        marginTop: "5px",
    }}
    onClick={handleClickOpen}
>
    <Box
        sx={{
            fontWeight: "500",
            backgroundColor: approved ? theme.palette.success.main : theme.palette.info.main,
            color:theme.palette.text.secondary,
            borderRadius: "7px",
            width: "80%",
            margin: "10px 2px 2px 2px", // Center the box with auto margin
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        <Box sx={{ padding: "0" }}>
        {formatTime(startDate).slice(0, 5)} -{" "}
        {endDate ? (
            formatTime(endDate).slice(0, 5)
        ) : (
            <span>Active</span>
        )}
        </Box>
    </Box>
    <Box>
        <Box>
            Shift:{" "}
            <span>{totalShiftLength && totalShiftLength.slice(0, 5)}</span>
        </Box>
        <Box>
            Break: <span>{totalBreak && totalBreak.slice(0, 5)}</span>
        </Box>
    </Box>
        <Box>
   
            Shift w/o break:{" "}
            <span>{shiftWithoutBreak && shiftWithoutBreak.slice(0, 5)} </span>
        </Box>
    </Box>


            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.dialog.paper,
                        borderRadius: "20px",
                        color: theme.palette.text.secondary,
                        width: "max-content",
                        margin: "auto"
                    },
                }}
            >
                <DialogContent style={{ display: "flex", padding: "0", color: theme.palette.text.primary }}>
                    <div
                        style={{
                            padding: "20px",
                            backgroundColor: theme.palette.card.bg,
                            width: "max-content",
                            color: theme.palette.text.primary
                        }}
                    >
                        {/* <img
                            src={`https://avatar.oxro.io/avatar.svg?name=${props.employee.fname.toUpperCase()}&background=random`}
                            alt="avatar"
                            style={{
                                width: "7rem",
                                height: "7rem",
                                margin: "auto",
                                marginBottom: "0.6rem",
                                borderRadius: "50%",
                            }}
                        /> */}
                        <Avatar
                            sx={{ width: 100, height: 100, margin: "auto", marginBottom: "0.6rem", fontSize: "3rem",color: theme.palette.text.secondary }}
                        >
                            {props.employee.fname[0].toUpperCase()}
                        </Avatar>


                        <Link style={{ textDecoration: "none", color: theme.palette.text.primary}} to={`/employee/${props.employeeId}`}>
                            <div
                                style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    textAlign: "center",
                                }}
                            >
                                {props.employee.fname} {props.employee.lname}
                            </div>
                        </Link>
                        <p style={{ textAlign: "center", marginTop: "0", marginBottom: "0.2rem" }}>
                            {props.employee.title}
                        </p>
                        <Grid container spacing={2}>
                            <Grid item xs={3} md={3}>
                                <AccessTimeIcon />
                            </Grid>
                            <Grid item xs={9} md={9}>
                                <span style={{ fontWeight: "600", textAlign: "center" }}>
                                    {totalShiftLength.slice(0, 5)}
                                </span>
                                <br />
                                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>Total length</span>
                                <br />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} md={3}></Grid>
                            <Grid item xs={9} md={9}>
                                <span style={{ fontWeight: "600", textAlign: "center" }}>{totalBreak.slice(0, 5)}</span>
                                <br />
                                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>Total Break</span>
                                <br />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} md={3}></Grid>
                            <Grid item xs={9} md={9}>
                                <span
                                    style={{
                                        fontWeight: "600",
                                        textAlign: "center",
                                        color: "#ee5622",
                                    }}
                                >
                                    {shiftWithoutBreak.slice(0, 5)}
                                </span>
                                <br />
                                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>Shift w/o break</span>
                                <br />
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ padding: "20px" }}>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4 style={{ fontWeight: "400" }}>
                                Edit Shift on:{" "}
                                <span style={{ fontWeight: "600" }}>
                                    {new Date(props.shift.startDate).toString().slice(0, 15)}
                                </span>
                            </h4>
                            {!props.shift.endDate && <h4 style={{ color: theme.palette.warning.main }}>Currently Active</h4>}
                        </div>

                        <Grid container spacing={2}>
                            <Grid item xs={5}>

                                <Typography variant="body1" style={{ fontWeight: "600" }}>
                                    Start Time:  <Chip
                                        label={formatTime(props.shift.startDate).slice(0, 5)}
                                        variant="outlined"
                                        sx={{color: theme.palette.text.primary}}
                                        size="small"
                                        style={{ marginRight: "5px" }}
                                    />
                                </Typography>


                            </Grid>
                            <Grid item xs={5}>

                                <Typography variant="body1" style={{ fontWeight: "600" }}>
                                    End Time:  <Chip
                                        label={formatTime(props.shift.endDate).slice(0, 5)}
                                        variant="outlined"
                                        sx={{color: theme.palette.text.primary}}
                                        size="small"
                                        style={{ marginRight: "5px" }}
                                    />
                                </Typography>

                            </Grid>
                            <Grid item xs={5}>
                                <img
                                    src={props.shift.startImage}
                                    alt="Start Shift Image"
                                    style={{
                                        width: "100%",
                                        marginTop: "8px",
                                        borderRadius: "5px",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <img
                                    src={props.shift.endImage}
                                    alt="End Shift Image"
                                    style={{
                                        width: "100%",
                                        marginTop: "8px",
                                        borderRadius: "5px",
                                    }}
                                />
                            </Grid>
                        </Grid>



                        <Typography variant="h6" style={{ fontWeight: "600", marginTop: "10px" }}>
                            Breaks
                        </Typography>
                        {props.shift.break === null 
                        ||
                        props.shift.break.length === 0
                        
                        ? (
                            <div>No breaks Found.</div>
                        ) : (
                            props.shift.break.map((brk, index) => (
                                <Grid container spacing={2} key={brk.start}>

                                    <Grid item xs={10}>
                                        <Typography variant="body1" >
                                            Break {index + 1}:  <Chip
                                                label={formatTime(brk.start).slice(0, 5) + " to " + formatTime(brk.end).slice(0, 5) + " = " + subtractTime(brk.start, brk.end)}
                                                variant="outlined"
                                                sx={{color: theme.palette.text.primary}}
                                                size="small"
                                                style={{ marginRight: "5px" }}
                                            />

                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))
                        )}
                       



                        {/* Rest of the code for shift approval, timeline, and buttons */}
                        <ShiftTimeLine shiftTimelines={props.shift.shiftTimelines} />

                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
