import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Alert, Avatar, Chip, Snackbar, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import { ButtonBase } from '@mui/material';
import { useSelector } from "react-redux";
import { Typography } from '@mui/material';
import PinAuth from "./PinAuth";
import Welcome from "./Welcome";
import ClearIcon from "@mui/icons-material/Clear";
import GridLoader from "react-spinners/GridLoader";
import { useTheme } from "@emotion/react";
import OutOfRadiusDialog from "./OutOfRadiusDialog";
import { Approval } from "@mui/icons-material";
import ApprovalDialog from "./ApprovalDialogBox";








const EmployeesBox = (props) => {
  const [search, setSearch] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [workingEmployeesList, setWorkingEmployeesList] = useState([]);
  const [workingEmployees, setWorkingEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);
  const theme = useTheme();
  const [isGeoOut, setIsGeoOut] = useState(false);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [allowedLatLong, setAllowedLatLong] = useState();
  const [allowedRadius, setAllowedRadius] = useState();
  const [distance, setDistance] = useState();
  const [requestSent, setRequestSent] = useState(false);
  const [statusCode, setStatusCode] = useState(null);

  const Tab = styled(TabUnstyled)`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  width: 100%;
  height: 3rem;
  padding: 0.5rem 0.75rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.miscBg.bg};
  border-radius: 9px 9px 9px 9px;
  color: ${theme.palette.text.primary};

  &.${tabUnstyledClasses.selected} {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.text.secondary};
  }
`;

  const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 1rem;
`;

  const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${theme.palette.background.paper};
  color: ${theme.palette.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding:0;
  border-radius: 10px;
`;








  const searchHandler = (e) => {
    setSearch(e.target.value);
    if (e.target.value !== "") {
      let searchedEmployees = employeesList;
      searchedEmployees = searchedEmployees.filter(function (currentElement) {
        return (
          (currentElement.email &&
            currentElement.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (currentElement.employeeDetail.fname &&
            currentElement.employeeDetail.fname
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (currentElement.employeeDetail.lname &&
            currentElement.employeeDetail.lname
              .toLowerCase()
              .includes(e.target.value.toLowerCase()))
        );
      });
      let searchedWorkingEmployees = workingEmployeesList;
      searchedWorkingEmployees = searchedWorkingEmployees.filter(function (
        currentElement
      ) {
        return (
          (currentElement.email &&
            currentElement.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (currentElement.employeeDetail.fname &&
            currentElement.employeeDetail.fname
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (currentElement.employeeDetail.lname &&
            currentElement.employeeDetail.lname
              .toLowerCase()
              .includes(e.target.value.toLowerCase()))
        );
      }
      );
      setWorkingEmployees(searchedWorkingEmployees);
      setEmployees(searchedEmployees);
    } else {
      setEmployees(employeesList);
      setWorkingEmployees(workingEmployeesList);
    }
  };
  const userLogin = useSelector((state) => state.userLogin);
  const { companyInfo } = userLogin;
  

  const sortEmployees = (employees) => {
    return employees.sort((a, b) => {
      if (a.employeeDetail.fname < b.employeeDetail.fname) {
        return -1;
      }
    }
    )
  }
  const fetchEmployees = (latitude, longitude) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${companyInfo.JWT_TOKEN}`,
        "Lat": latitude,
        "Lon": longitude
  
      },
    };
    axios.get(
      `${process.env.REACT_APP_API_BACKEND}/api/kiosk/dashboard`,
      config
    ).then((res) => {
      if(res.data.requireApproval === true){
        if(!localStorage.getItem("kioskRequestData")){
          localStorage.setItem("kioskRequestData", JSON.stringify(res.data.kioskRequest));
        }
      }
      setEmployeesList(sortEmployees(res.data.employees));
      setEmployees(sortEmployees(res.data.employees));
      const temp = res.data.employees.filter((employee) => employee.shiftStatus !== "Not Working");
      setWorkingEmployeesList(sortEmployees(temp));
      setWorkingEmployees(sortEmployees(temp));
      sessionStorage.setItem("showLogout", true);
      setLoading(false);
    }
    ).catch((err) => {
      if (err.response.data.code === "inactive") {
        setError(err.response.data.message);
      }
      else if (err.response.data.code === "outOfRadius") {
        setError(err.response.data.message);
        setAllowedLatLong(err.response.data.allowedLatLong);
        setAllowedRadius(err.response.data.allowedRadius);
        setDistance(err.response.data.distance);
        setIsGeoOut(true);
      }
      else if (err.response.data.code === "noname") {
        setStatusCode("noname");
        setShowApprovalDialog(true);
      }
      else if (err.response.data.code === "pending") {
        setStatusCode("pending");
        setRequestSent(true);
        setShowApprovalDialog(true);
      }
      else if (err.response.data.code === "rejected") {
        setStatusCode("rejected");
        setRequestSent(true);
        setShowApprovalDialog(true);
      }

      else {
        setError(err.response.data.message);
        setTimeout(() => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("companyInfo");
          window.location.reload();
        }
          , 5000);
      }
      setLoading(false);
    })

  }

  const getAvatarName = (employee) => {
    if (employee.employeeDetail) {
      if (employee.employeeDetail.fname && employee.employeeDetail.lname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.fname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.lname) {
        return employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
    }
    else {
      return employee.email.charAt(0).toUpperCase();
    }
  }
  const getEmployeeName = (employee) => {
    if (employee.employeeDetail.fname && employee.employeeDetail.lname) {
      return employee.employeeDetail.fname + " " + employee.employeeDetail.lname;
    }
    else if (employee.employeeDetail.fname) {
      return employee.employeeDetail.fname;
    }
    else if (employee.employeeDetail.lname) {
      return employee.employeeDetail.lname;
    }
    else if (employee.email) {
      return employee.email;
    }
    return "Unknown";
  }


  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          // Compare with expected location and debug accordingly
          setLat(latitude);
          setLon(longitude);
          fetchEmployees(latitude, longitude);
        },
        (error) => {
          fetchEmployees(null, null);
          console.error("Error getting geolocation:", error);
        },
        { enableHighAccuracy: true } // Request high accuracy if available
      );
    } else {
      fetchEmployees(null, null);
    }
  }, []);
  

 

  return (
    <div >
      <Grid>
        {error &&
          <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "100" }}>
            <Alert severity="error">{error}</Alert>
          </div>
        }
        <Grid item xs={12} >
          <TextField
            id="outlined-basic"
            style={{ 
              width: "100%", 
              marginBottom: "1rem",  // Removed margin
              borderRadius: "10px"
            }}
            type="text"
            value={search}
            onChange={searchHandler}
            size="small"
            InputProps={{
              style: {
                padding: "0",  // Removed padding
                borderRadius: "10px",
                paddingLeft: "10px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              placeholder: "Search by name/email",
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      borderRadius: "0 10px 10px 0",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      height: "40px",
                      margin: "0",  // Removed margin
                      boxShadow: "none",  // Removed box shadow
                      color: theme.palette.text.secondary,
                      backgroundColor: theme.palette.primary.main,
                    }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                      setSearch("");
                      setEmployees(employeesList);
                    }}
                  >
                    Clear
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',  // Changed focus border color to black
                },
              },
            }}
            variant="outlined"
          />
          <Paper square={false} style={{ height: "70vh", overflow: "auto" }} className="employee-box">
            <TabsUnstyled defaultValue={0}>
              <TabsList style={{ position: "sticky", top: "0", zIndex: "100", borderRadius: "10px" }}>
                <Tab>All</Tab>
                <Tab>Working</Tab>
              </TabsList>
              <TabPanel value={0}>

                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      marginTop: "200px",
                    }}
                  >
                    <GridLoader
                      size={25} />
                  </div>
                ) : (
                  <div style={{ overflow: "hidden", willChange: "transform", direction: "1tr" }}>
                    {employees.map((employees, index) => (

                      <React.Fragment key={index}>

                        <ButtonBase key={index} style={{
                          width: "100%", height: "50px", justifyContent: "flex-start", paddingLeft: "16px",
                          borderBottom: "1px solid #7c7c7c7d"
                        }} onClick={() => props.handleEmployee(employees)}>
                          <Avatar style={{
                            width: "2rem", height: "2rem", marginRight: "1rem", color: "#fff", fontWeight: "500", fontSize: "1rem",
                             borderRadius: "20%"
                          }}
                          >
                            {getAvatarName(employees)}
                          </Avatar>
                          <Typography variant="body1" style={{ fontSize: "1.2rem", fontWeight: "400" }}>
                            {getEmployeeName(employees)}
                          </Typography>
                          {employees.shiftStatus !== "Not Working" && (
                            //if emplye is on break
                            employees.shiftStatus === "On Break" ? (
                              <Chip sx={{ backgroundColor: theme.palette.info.main, fontSize: "0.9rem", color: "#fff", position: "absolute", right: "2rem" }} label={"Active"} />
                            ) : (
                              <Chip sx={{ backgroundColor: theme.palette.success.main, fontSize: "0.9rem", color: "#fff", position: "absolute", right: "2rem" }} label={"Active"} />
                            )

                          )}
                        </ButtonBase>
                      </React.Fragment>
                    ))}
                  </div>
                )
                }
              </TabPanel>
              <TabPanel value={1}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      marginTop: "200px",
                    }}
                  >
                    <GridLoader
                      size={25} />
                  </div>
                ) : (
                  <div style={{ overflow: "hidden", willChange: "transform", direction: "1tr" }}>
                    {workingEmployees.map((employees, index) => (
                      employees.shiftStatus !== "Not Working" && (
                        <ButtonBase key={index} style={{
                          width: "100%", height: "50px", justifyContent: "flex-start", paddingLeft: "16px",
                          borderBottom: "1px solid #7c7c7c7d"
                        }} onClick={() => props.handleEmployee(employees)}>

                          <Avatar style={{
                            width: "2rem", height: "2rem", marginRight: "1rem", color: "#fff", fontWeight: "500", fontSize: "1rem",
                             borderRadius: "20%"
                          }}>
                            {getAvatarName(employees)}
                          </Avatar>
                          <Typography variant="body1" style={{ fontSize: "1.2rem", fontWeight: "400" }}>
                            {getEmployeeName(employees)}
                          </Typography>
                          {employees.shiftStatus == "On Break" ? (
                            <Chip sx={{ backgroundColor: theme.palette.info.main, fontSize: "0.9rem", color: "#fff", position: "absolute", right: "2rem" }} label={"Active"} />
                          ) : (
                            <Chip sx={{ backgroundColor: theme.palette.success.main, fontSize: "0.9rem", color: "#fff", position: "absolute", right: "2rem" }} label={"Active"} />
                          )
                          }
                        </ButtonBase>)
                    ))}
                  </div>
                )
                }
              </TabPanel>
            </TabsUnstyled>
          </Paper>
        </Grid>
      </Grid>
      {isGeoOut && <OutOfRadiusDialog 
      open={isGeoOut} 
      onClose={() => setIsGeoOut(false)} 
      currLat={lat} currLong={lon} 
      allowedLat={allowedLatLong.lat} 
      allowedLong={allowedLatLong.lon} 
      allowedRadius={allowedRadius} 
      distance={distance}
      />}
      <ApprovalDialog open={showApprovalDialog} requestSent={requestSent} setRequestSent={setRequestSent} statusCode={statusCode} />
    </div>
  );
};

export default EmployeesBox;